import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          "Last name": "Last name",
          French: "French",
          English: "English",
          "Language change": "Language change",
          "Job title": "Job title",
          "Seniority in position": "Seniority in position",
          SIRET: "SIRET",
          "Company name": "Company name",
          Submit: "Submit",
          Cancel: "Cancel",
          "Professional e-mail": "Professional e-mail",
          "Language(s) Known:": "Language(s) Known:",
          "Your Settings": "Your Settings",
          "Your Company Settings": "Your Company Settings",
          Locale: "Locale",
          Region: "Region",
          National: "National",
          City: "City",
          "Add a word/business sector": "Add a word/business sector",
          Word: "Word",
          Theme: "Theme",
          Add: "Add",
          "Your list of 3 words": "Your list of 3 words",
          "Find new friends": "Find new friends",
          "Connect with the world": "Connect with the world",
          "Create new events": "Create new events",
          "Enter email": "Enter email",
          Password: "Password",
          "Forgot password?": "Forgot password?",
          "Remember Me": "Remember Me",
          "Don't have an account? ": "Don't have an account? ",
          "Sign up": "Sign up",
          Facebook: "Facebook",
          Twitter: "Twitter",
          Instagram: "Instagram",
          "Email address": "Email address",
          "Sign in": "Sign in",
          "Please complete this 3 steps form to sign up. Every field is required.":
            "Please complete this 3 steps form to sign up. Every field is required.",
          "Sign Up": "Sign Up",
          "Your Full Name": "Your Full Name",
          "Already Have Account ? ": "Already Have Account ? ",
          "Log In": " Log In",
          "I accept ": "I accept ",
          "Terms and Conditions": "Terms and Conditions",
          "Please choose a location for the visibility of your post":
            "Please choose a location for the visibility of your post",
          "What are you looking for?": "What are you looking for?",
          "Start a post": "Start a post",
          "Perimeter (km)": "Perimeter (km)",
          "Languages Known:": "Languages Known:",
          "Post's visibility time": "Post's visibility time",
          "Your infos": "Your infos",
          "Your company's infos": "Your company's infos",
          "Your list of words": "Your list of words",
          Back: "Back",
          Next: "Next",
          "Your first name": "Your first name",
          "Your last name": "Your last name",
          "The first business social media is born and its name is SEEKS":
            "The first business social media is born and its name is SEEKS",
          "The adage 'Client is king' has never been so true. You don't have to seek anymore, just to choose.":
            "The adage 'Client is king' has never been so true. You don't have to seek anymore, just to choose.",
          "The world is divided in two categories, sellers and buyers. With SEEKS you are both.":
            "The world is divided in two categories, sellers and buyers. With SEEKS you are both.",
          "Now is the most import part of the parm, the choice of your words (exactly 3 for now) which will define the posts you'll be able to see in SEEKS.":
            "Now is the most import part of the parm, the choice of your words (exactly 3 for now) which will define the posts you'll be able to see in SEEKS.",
          "Pick couples of word/business sector related to your business activity to be able to provide your goods/services to everyone needing it !":
            "Pick couples of word/business sector related to your business activity to be able to provide your goods/services to everyone needing it !",
          "First name is required": "First name is required",
          "Last name is required": "Last name is required",
          "Invalid email": "Invalid email",
          "Email is required": "Email is required",
          "Password is required": "Password is required",
          "Company name is required": "Company name is required",
          "SIRET is required": "SIRET is required",
          "Job title is required": "Job title is required",
          "Seniority is required": "Seniority is required",
          "Create Post": "Create Post",
          "Please search for an address and pick one":
            "Please search for an address and pick one",
          "Company address": "Company address",
          "Your words and packages": "Your words and packages",
          "Your package:": "Your package:",
          "Your option(s):": "Your option(s):",
          "Posts found": "Posts found",
          "Please select a password:": "Please select a password:",
          "8 characters minimum": "8 characters minimum",
          'At least one capital letter, one lowercase letter, one digit, one special character among !@#$%^&*(),.?":{}|<>+':
            'At least one capital letter, one lowercase letter, one digit, one special character among !@#$%^&*(),.?":{}|<>+',
          "Not too common": "Not too common",
          "Reset Password": "Reset Password",
          "Password updated successfully!": "Password updated successfully!",
          "Account created successfully!": "Account created successfully!",
          "Account updated successfully!": "Account updated successfully!",
          "Email sent successfully!": "Email sent successfully!",
          "Enter your email address and we will send you a mail to reset your password.":
            "Enter your email address and we will send you a mail to reset your password",
          "Get activation token": "Get activation token",
          "Enter your email address and we will send you a token to activate your account.":
            "Enter your email address and we will send you a token to activate your account.",
          " click here to request a new token":
            " click here to request a new token",
          "Enter your email address and password to access the app.":
            "Enter your email address and password to access the app.",
          "Looks like your account is inactive. Verify your email for your activation link (valid for 24 hours only) or":
            "Looks like your account is inactive. Verify your email for your activation link (valid for 24 hours only) or",
          "Business sector": "Business sector",
          "Validate changes": "Validate changes",
          "SEEKS advises you to provide as much detail as possible in your search. Quantity, size, weight, color, budget, timing, circumstances, photo, document, links... With the 3-word system, the more detailed your search, the greater the chance of being contacted by the right person.":
            "SEEKS advises you to provide as much detail as possible in your search. Quantity, size, weight, color, budget, timing, circumstances, photo, document, links... With the 3-word system, the more detailed your search, the greater the chance of being contacted by the right person.",
          "When a request is posted by a member, SEEKS will only forward it to you if one of your words appears in it. Choose them carefully to ensure that you respond to a maximum number of requests.":
            "When a request is posted by a member, SEEKS will only forward it to you if one of your words appears in it. Choose them carefully to ensure that you respond to a maximum number of requests.",
          Price: "Price",
          "Price on quote": "Price on quote",
          "Link (product or your company)": "Link (product or your company)",
          Text: "Text",
          "Added a new Post in sector": "Added a new Post in sector",
          Exclude: "Exclude",
          "Filter your words": "Filter your words",
          "My profile": "My profile",
          "Sign out": "Sign out",
          "Account settings": "Account settings",
          "My posts": "My posts",
          "My answers": "My answers",
          "See answers": "See answers",
          Answers: "Answers",
          Answer: "Answer",
          "You can add words here, to filter results on these only. If you check the box, it will remove these words from the search.":
            "You can add words here, to filter results on these only. If you check the box, it will remove these words from the search.",
          "Verify number of posts": "Verify number of posts",
          "Text post is required": "Text post is required",
          "Limit date is required": "Limit date is required",
          Email: "Email",
          None: "None",
          "(3 words)": "(3 words)",
          "Company address is required": "Company address is required",
          "These words are linked to sectors, so that someone looking for bass fishing equipment 🎣, does not see his request sent to a luthier 🎸.":
            "These words are linked to sectors, so that someone looking for bass fishing equipment 🎣, does not see his request sent to a luthier 🎸.",
          Address: "Address",
          "Address is required": "Address is required",
          Posts: "Posts",
          "Text answer is required": "Text answer is required",
          "No answer found": "No answer found",
          "No answer yet": "No answer yet",
          "No post found": "No post found",
          answers: "answers",
          answer: "answer",
          Link: "Link",
          Author: "Author",
          Content: "Content",
          "See most recent posts": "See most recent posts",
          "See most recent answers": "See most recent answers",
          "See author details and attachment(s)":
            "See author details and attachments",
          "Date and time": "Date and time",
          "See attachment(s)": "See attachment(s)",
          "Your account has been successfully created. Please check your e-mails to find the activation link.":
            "Your account has been successfully created. Please check your e-mails to find the activation link.",
          "Your account has been successfully created and you are now connected. Just click on the button below to be redirected to the home page.":
            "Your account has been successfully created and you are now connected. Just click on the button below to be redirected to the home page.",
          "Are you sure you want to delete this post?":
            "Are you sure you want to delete this post?",
          "Are you sure you want to delete this answer?":
            "Are you sure you want to delete this answer?",
          "You can't undo this operation": "You can't undo this operation",
          Yes: "Yes",
          No: "No",
          "Send answer": "Send answer",
          "No image nor attached file": "No image nor attached file",
          "Here are some more posts related to your sector and not only the words you chose.":
            "Here are some more posts related to your sector and not only the words you chose.",
          Hello: "Hello",
          "Author's details": "Author's details",
          "Go to Home page": "Go to Home page",
          "Error !": "Error !",
          "Success !": "Success !",
          "Post created successfully!": "Post created successfully!",
          "Answer created successfully!": "Answer created successfully!",
          Skip: "Skip",
          "Reactivate tutorial": "Reactivate tutorial",

          "Tutorial reactivated": "Tutorial reactivated",
          "You reactived the tutorial. To see it again, go back to the home page and reload it.":
            "You reactived the tutorial. To see it again, go back to the home page and reload it.",
          "Idea box": "Idea box",
          "Average rating": "Average rating",
          "No rating": "No rating",
          "Scroll on top of the page": "Scroll on top of the page",
          "SIRET (14 characters)": "SIRET (14 characters)",
          "See concept": "See concept",
        },
      },
      fr: {
        translations: {
          "Last name": "Nom de famille",
          "First name": "Prénom",
          French: "Français",
          English: "Anglais",
          "Language change": "Choix de la langue",
          "Job title": "Intitulé du poste",
          "Seniority in position": "Ancienneté dans le poste",
          SIRET: "SIRET",
          "Company name": "Nom de l'entreprise",
          Submit: "Valider",
          Cancel: "Annuler",
          "Professional e-mail": "E-mail professionnel",
          "Language(s) Known:": "Langue(s) parlée(s)",
          "Your Settings": "Vos paramètres",
          "Your Company Settings": "Paramètres de votre entreprise",
          Locale: "Local",
          Region: "Région",
          National: "National",
          City: "Ville",
          "Add a word/business sector": "Ajouter un mot/secteur d'activité",
          Word: "Mot",
          Theme: "Thème",
          Add: "Ajouter",
          "Your list of words": "Votre liste de mots",
          "Find new friends": "Trouver de nouveaux amis",
          "Connect with the world": "Connectez-vous avec le monde",
          "Create new events": "Créer de nouveaux événements",
          "Enter email": "Entrer l'email",
          Password: "Mot de passe",
          "Forgot password?": "Mot de passe oublié ?",
          "Remember Me": "Se souvenir de moi",
          "Don't have an account? ": "Vous n'avez pas de compte ? ",
          "Sign up": "Inscrivez-vous",
          Facebook: "Facebook",
          Twitter: "Twitter",
          Instagram: "Instagram",
          "Email address": "Adresse e-mail",
          "Sign in": "Se connecter",
          "Please complete this 3 steps form to sign up. Every field is required.":
            "Veuillez compléter ce formulaire en 3 étapes. Chaque champ est obligatoire.",
          "Sign Up": "S'inscrire",
          "Your Full Name": "Votre nom complet",
          "Already Have Account ? ": "Vous avez déjà un compte ? ",
          "Log In": " Connectez vous",
          "I accept ": "J'accepte les ",
          "Terms and Conditions": "Termes et Conditions",
          "Please choose a location for the visibility of your post":
            "Veuillez choisir une localisation pour la visibilité de votre post",
          "What are you looking for?": "Que recherchez vous?",
          "Start a post": "Commencer un post",
          "Perimeter (km)": "Perimètre (km)",
          "Languages Known:": "Langues parlées:",
          "Post's visibility time": "Durée de visibilité du post",
          "Your infos": "Votre profil",
          "Your company's infos": "Votre entreprise",
          "Your list of 3 words": "La liste de vos 3 mots",
          Back: "Précédent",
          Next: "Suivant",
          "Your first name": "Votre prénom",
          "Your last name": "Votre nom",
          "The first business social media is born and its name is SEEKS":
            "Le premier réseau social business est né et il s’appelle SEEKS",
          "The adage 'Client is king' has never been so true. You don't have to seek anymore, just to choose.":
            "Le proverbe « le client est roi » n’a jamais été aussi vrai. Ce n’est plus à vous de chercher, il vous suffit de choisir.",
          "The world is divided in two categories, sellers and buyers. With SEEKS you are both.":
            "Le monde se divise en deux catégories, les vendeurs et les acheteurs. Chez SEEKS vous êtes les deux.",
          "Now is the most import part of the parm, the choice of your words (exactly 3 for now) which will define the posts you'll be able to see in SEEKS.":
            "Nous arrivons à la partie la plus importante du formulaire, le choix des mots (exactement 3, pour le moment) qui définiront les posts que vous verrez sur Seeks.",
          "Pick couples of word/themes related to your business activity to be able to provide your goods/services to everyone needing it !":
            "Choisissez des couples de mots/secteurs d'activité en rapport avec votre secteur d'activité pour pouvoir proposer vos produits/services au plus grand nombre !",
          "First name is required": "Prénom est requis",
          "Last name is required": "Nom de famille est requis",
          "Invalid email": "E-mail invalide",
          "Email is required": "E-mail est requis",
          "Password is required": "Mot de passe est requis",
          "Company name is required": "Nom de l'entreprise est requis",
          "SIRET is required": "SIRET est requis",
          "Job title is required": "Titre du poste est requis",
          "Seniority is required": "Ancienneté dans le poste est requis",
          "No active account found with the given credentials":
            "Aucun compte trouvé avec cet e-mail/mot de passe.",
          "Create Post": "Créer un post",
          "Please search for an address and pick one":
            "Veuillez rechercher une adresse et la sélectionner",
          "Company address": "Adresse de l'entreprise",
          "Your words and packages": "Vos mots et forfaits",
          "Your package:": "Votre forfait:",
          "Your option(s):": "Vos option(s):",
          "Posts found": "Posts trouvés",
          "Please select a password:": "Veuillez choisir un mot de passe:",
          "8 characters minimum": "8 caractères minimum",
          'At least one capital letter, one lowercase letter, one digit, one special character among !@#$%^&*(),.?":{}|<>+':
            'Au moins une lettre majuscule, une minuscule, un chiffre et un caractère spécial parmi !@#$%^&*(),.?":{}|<>+',
          "Not too common": "Non commun (Password123!...)",
          "Reset Password": "Réinitialiser mot de passe",
          "Password updated successfully!":
            "Mot de passe mis à jour avec succès!",
          "Account created successfully!": "Compte créé avec succès!",
          "Account updated successfully!": "Compte mis à jour avec succès!",
          "Email sent successfully!": "Email envoyé avec succès!",
          "Enter your email address and we will send you a mail to reset your password.":
            "Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
          "Get activation token": "Demander un token d'activation",
          "Enter your email address and we will send you a token to activate your account.":
            "Entrez votre adresse e-mail et nous vous enverrons un nouveau lien pour activer votre compte.",
          " click here to request a new token":
            " cliquez ici pour en demander un nouveau",
          "Enter your email address and password to access the app.":
            "Entrez votre adresse mail et mot de passe pour accéder à l'application.",
          "Looks like your account is inactive. Verify your email for your activation link (valid for 24 hours only) or":
            "Il semblerait que votre compte est inactif. Vérifiez votre boite mail pour trouver le lien d'activation (valable 24 heures seulement) ou",
          "Business sector": "Secteur d'activité",
          "Validate changes": "Valider changements",
          "SEEKS advises you to provide as much detail as possible in your search. Quantity, size, weight, color, budget, timing, circumstances, photo, document, links... With the 3-word system, the more detailed your search, the greater the chance of being contacted by the right person.":
            "SEEKS vous conseille de détailler le plus possible votre recherche. Nombre, taille, poids, couleur, budget, deadline, circonstance, photo, document, liens... Grâce au système des 3 mots, plus votre recherche est détaillée plus vous aurez de chance d'être contacté par le bon interlocuteur.",
          "When a request is posted by a member, SEEKS will only forward it to you if one of your words appears in it. Choose them carefully to ensure that you respond to a maximum number of requests.":
            "Lorsqu'une demande sera postée par un membre, SEEKS vous la transmet seulement si l'un de vos mots apparait dans celle-ci. Choisissez-les avec soin pour être sûr de répondre à un maximum de demandes.",
          Price: "Prix",
          "Price on quote": "Prix sur devis",
          Link: "Lien",
          "Link (product or your company)":
            "Lien (produit ou votre entreprise)",
          Text: "Texte",
          "Added a new Post in sector": "A créé un post dans le secteur",
          Exclude: "Exclure",
          "Filter your words": "Filtrer vos mots",
          "My profile": "Mon profil",
          "Sign out": "Déconnexion",
          "Account settings": "Paramètres du compte",
          "My posts": "Mes posts",
          "My answers": "Mes réponses",
          "See answers": "Voir réponses",
          Answers: "Réponses",
          Answer: "Répondre",
          "You can add words here, to filter results on these only. If you check the box, it will remove these words from the search.":
            "Ajouter des mots pour filtrer les résultats contenant seulement ces derniers. Cochez la case pour retirer ces mots de la recherche",
          "Verify number of posts": "Verifier nombre de posts",
          "Text post is required": "Texte poste est requis",
          "Limit date is required": "Date limite est requis",
          Email: "Email",
          None: "Aucune",
          "(3 words)": "(3 mots)",
          "Company address is required": "Adresse de l'entreprise est requis",
          "These words are linked to sectors, so that someone looking for bass fishing equipment 🎣, does not see his request sent to a luthier 🎸.":
            "Ces mots sont associés à des thèmes, afin d'éviter que, par exemple, une personne à la recherche de matériel de pêche 🎣 ne voie sa demande dirigée vers un maraîcher producteur de pêches 🍑.",
          Address: "Adresse",
          "Address is required": "Adresse est requis",
          Posts: "Posts",
          "Text answer is required": "Texte réponse est requis",
          "No answer found": "Aucune réponse trouvée",
          "No answer yet": "Aucune réponse pour le moment",
          "No post found": "Aucun post trouvé",
          answers: "réponses",
          answer: "réponse",
          Author: "Auteur",
          Content: "Contenu",
          "See most recent posts": "Voir les postes les plus récents",
          "See most recent answers": "Voir les réponses les plus récentes",
          "See author details and attachment(s)":
            "Voir les détails de l'auteur et les pièce(s) jointe(s)",
          "Date and time": "Horodatage",
          "See attachment(s)": "Voir pièce(s) jointe(s)",
          "Your account has been successfully created. Please check your e-mails to find the activation link.":
            "Votre compte a été créé avec succès. Vérifiez vos mails pour trouver le lien d'activation.",
          "Your account has been successfully created and you are now connected. Just click on the button below to be redirected to the home page.":
            "Votre compte a été créé avec succès et vous êtes maintenant connecté. Cliquez sur le bouton ci-dessous pour être redirigé sur la page d'accueil.",
          "Are you sure you want to delete this post?":
            "Êtes vous sûr de vouloir supprimer ce post?",
          "Are you sure you want to delete this answer?":
            "Êtes vous sûr de vouloir supprimer cette réponse?",
          "You can't undo this operation": "Cette action est irréversible",
          Yes: "Oui",
          No: "Non",
          "Send answer": "Envoyer réponse",
          "No image nor attached file": "Pas d'image ni de PJ",
          "Here are some more posts related to your sector and not only the words you chose.":
            "Voici d'autres posts en lien avec votre secteur et pas seulement les mots que vous avez choisi.",
          Hello: "Bonjour",
          "Author's details": "Détails de l'auteur",
          "Go to Home page": "Redirection vers la page d'accueil",
          "Error !": "Erreur !",
          "Success !": "Succès !",
          "Post created successfully!": "Post créé avec succès!",
          "Answer created successfully!": "Réponse créé avec succès!",
          Share: "Partager",
          Last: "Fin",
          Skip: "Passer",
          "Reactivate tutorial": "Réactiver le tutoriel",
          "Tutorial reactivated": "Tutoriel réactivé",
          "You reactived the tutorial. To see it again, go back to the home page and reload it.":
            "Vous avez réactivé le tutoriel. Pour le revoir, retourner sur la page d'accueil et rechargez la.",
          "Idea box": "Boîte à idées",
          "Any suggestion to improve SEEKS?": "Une idée pour améliorer SEEKS?",
          "Send suggestion": "Envoyer idée",
          "Average rating": "Moyenne",
          "No rating": "Aucune note",
          "This user has no rating yet. Click on the number of stars you want to rate him for this answer to your post.":
            "Cet utilisateur n'a pas encore été noté. Sélectionnez un nombre d'étoiles pour le noter sur sa réponse à votre post.",
          "This user is rated ": "Cet utilisateur est noté ",
          " for his past answers. Click on the number of stars you want to rate him for this answer to your post.":
            " pour ses réponses précédentes. Sélectionnez un nombre d'étoiles pour le noter sur sa réponse à votre post.",
          "Are you sure you want to rate this user ":
            "Êtes vous sûr de vouloir noter cet utilisateur ",
          " stars?": " étoiles?",
          "You can't change your rating later.":
            "Vous ne pourrez plus changer cette note.",
          "Rating posted successfully!": "Notation envoyée avec succès!",
          "Scroll on top of the page": "Remonter en haut de page",
          "SIRET (14 characters)": "SIRET (14 caractères)",
          "See concept": "Voir le concept",
          "Learn more": "En savoir plus",
          "Please provide a reason for reporting.":
            "Veuillez entrer la raison de votre signalement.",
          Report: "Signaler",
          "Report sent successfully!": "Signalement envoyé!",
          "Report Answer": "Signaler une réponse",
          "Report Post": "Signaler un post",
          "Enter your reason here...": "Entrer la raison ici...",
          "Click to change your Avatar": "Cliquez pour changer votre avatar",
        },
      },
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    nsSeparator: false,
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
